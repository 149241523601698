import React, {useRef} from "react";

import { Container, Row , Col, Button} from "reactstrap";

import NavbarMain from "components/Navbars/NavbarMain.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import Buttons from "./IndexSections/Buttons";
import Inputs from "./IndexSections/Inputs";
import CustomControls from "./IndexSections/CustomControls";
import Menus from "./IndexSections/Menus";
import Navbars from "./IndexSections/Navbars";
import Tabs from "./IndexSections/Tabs";
import Progress from "./IndexSections/Progress";
import Pagination from "./IndexSections/Pagination";
import Labels from "./IndexSections/Labels";
import Alerts from "./IndexSections/Alerts";
import Modals from "./IndexSections/Modals";
import Datepicker from "./IndexSections/Datepicker";
import TooltipPopover from "./IndexSections/TooltipPopover";
import Carousel from "./IndexSections/Carousel";
import Icons from "./IndexSections/Icons";
import Prijzen from "./IndexSections/Prijzen";
import Download from "./IndexSections/Download";
import Pills from "./IndexSections/Pills";
import Typography from "./IndexSections/Typography";

import Image from '../assets/img/theme/index_image.jpg';
import NavbarIndex from "components/Navbars/NavbarIndex";
import usePageTracking from "hooks/pagetracking";
import BlogItem from "./examples/Index items/BlogItem"
import Text from "./IndexSections/Text";

function Index() {
  usePageTracking();

  const mainRef = useRef(null);

  return (
    <>
      <NavbarIndex  />
      <main ref={mainRef}>
        <div className="position-relative">
            <div 
              style={{ 
                backgroundImage: `url(${Image})`, 
                backgroundSize: 'cover', // Ensure the background covers the div
                backgroundPosition: 'center center', // Center the background image
                width: '100%', 
                height: '100vh', // Adjust the height as needed, '100vh' makes it full viewport height
              }}
            >
            <section className="section section-hero section-shaped">
            <Container className="shape-container d-flex align-items-center justify-content-left py-lg" style={{ height: '75vh' }}>
              <Row className="align-items-center text-center">
                <Col lg="11">
                  <div style={{ 
                      backgroundColor: 'rgba(255, 255, 255, .5)',
                      padding: '2rem',
                      borderRadius: '15px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}>
                    <h1 className="text-black mb-4" style={{ 
                      color: 'black', 
                      fontWeight: 'bold',
                    }}>
                      Elke Kwispel Telt!
                    </h1>
                    <p className="lead text-black mb-4" style={{ 
                      color: 'black',
                      fontWeight: 'bold'
                    }}>
                      De matchmaker tussen jouw hond en de oppas!
                    </p>
                    <div className="btn-wrapper mt-4">
                      <Button
                        className="btn-success btn-icon mb-3 mb-sm-0"
                        color="success"
                        href="/contact"
                        size="lg"
                      >
                        <span className="btn-inner--text">Schrijf je in!</span>
                      </Button>{" "}
                      <Button
                        className="btn-success btn-icon mb-3 mb-sm-0"
                        color="warning"
                        href="/diensten"
                        size="lg"
                      >
                        <span className="btn-inner--text">Onze Diensten</span>
                      </Button>{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            </section>
          </div>
            <BlogItem />
        <section className="section section-components">
          <Container>
            <Typography />
          </Container>
        </section>



        </div>
      {/*<Buttons />
        <Inputs />
        <section className="section">
        <Container>
        <CustomControls />
        <Menus />
        </Container>
        </section>
        <Navbars />
        <section className="section section-components">
          <Container>
            <Tabs />
            <Row className="row-grid justify-content-between align-items-center mt-lg">
              <Progress />
              <Pagination />
            </Row>
            <Row className="row-grid justify-content-between">
              <Pills />
              <Labels />
            </Row>
            <Alerts />
            <Typography />
            <Modals />
            <Datepicker />
            <TooltipPopover />
          </Container>
        </section>
        <Carousel />
        <Icons />
        <Prijzen />
                  <Download />*/}
      </main>
      <SimpleFooter />
    </>
  );
}

export default Index;
