/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: require("assets/img/theme/converted_image_2.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/theme/converted_image_3.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
];

class BlogItem extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-white font-weight-light">
                  Vind je ideale match!
                </h1>
                <p className="lead text-white mt-4">
                  SnuffelMatch is hét platform voor hondenbezitters die op zoek zijn naar een betrouwbare oppas. Of je nu iemand zoekt voor een korte tijd, lange termijn, of alleen voor het uitlaten, SnuffelMatch maakt het makkelijk. Meld je aan, zoek naar oppassers in jouw omgeving, en neem contact op met de perfecte match voor jouw hond. Vind vandaag nog de perfecte oppas voor jouw trouwe viervoeter!
                </p>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  href="https://www.snuffelmatch.nl"
                >
                  SnuffelMatch!
                </Button>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default BlogItem;
