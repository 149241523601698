import React, { useState } from "react";
import ContactformOppasser from "components/Contactforms/ContactformOppasser";
import ContactformHondeneigenaar from "components/Contactforms/ContactformHondeneigenaar";

import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import NavbarMain from "components/Navbars/NavbarMain.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import usePageTracking from "hooks/pagetracking";

function Contact() {
  usePageTracking();
  
  const [activeForm, setActiveForm] = useState(null);

  const handleFormSubmit = async ({ name, email, message }) => {
    console.log('Verzenden...', { name, email, message });
    return new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const toggleForm = (formType) => {
    setActiveForm(currentForm => currentForm === formType ? null : formType);
  };

  return (
    <>
      <NavbarMain />
      <main className="section section-lg section-shaped pb-250"> 
        <div style={{ paddingTop: '15%' }}></div>
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <div style={{ paddingTop: '5%' }}></div>

        <section className="section section-lg pt-lg-0 section-contact-us">
          <Container>
            <Row className="justify-content-center mt--300">
              <Col lg="8">
                <Card className="bg-gradient-secondary shadow">
                  <CardBody className="p-lg-5">
                    <h4 className="mb-1">Meer informatie voor in de toekomst?</h4>
                    <p className="mt-0">
                      Schrijf je in voor onze nieuwsbrief en wie weet ben jij wel onze eerste klant!
                    </p>

                    <Button onClick={() => toggleForm('hondeneigenaar')} block className="btn-round" color="default" size="lg" type="button">
                      Honden eigenaar
                    </Button>
                    <Button onClick={() => toggleForm('oppasser')} block className="btn-round" color="default" size="lg" type="button">
                      Honden oppasser
                    </Button>

                    {activeForm === 'oppasser' && <ContactformOppasser onSubmit={handleFormSubmit} />}
                    {activeForm === 'hondeneigenaar' && <ContactformHondeneigenaar onSubmit={handleFormSubmit} />}  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default Contact;
