import React, { useEffect } from "react";
import { useLocation, BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ReactDOM from "react-dom/client";

// Import your styles and components
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import Index from "views/Index.js";
import Diensten from "views/examples/Diensten.js";
import Prijzen from "views/examples/Prijzen.js";
import OverOns from "views/examples/OverOns.js";
import Contact from "views/examples/Contact.js";
import LandingPage from "views/examples/LandingPage";
import Feedback from "views/examples/Feedback";

import usePageTracking from "./hooks/pagetracking";

// Google Analytics
import ReactGA from 'react-ga';
const TRACKING_ID = "G-1KMDFWYVMX";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  usePageTracking();
  
  return (
    <Routes>
      <Route path="/" exact element={<Index />} />
      <Route path="/diensten" exact element={<Diensten />} />
      <Route path="/prijzen" exact element={<Prijzen />} />
      <Route path="/overons" exact element={<OverOns />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="/landingpage" exact element={<LandingPage />} />
      <Route path="/feedback" exact element={<Feedback />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
