import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledAlert,
  FormFeedback,
  Label
} from 'reactstrap';
import classnames from 'classnames';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AdministrationServiceId, CustomerServiceId, AdministrationTemplateId, CustomerTemplateId, YOUR_USER_ID } from '../../assets/email/EmailConfig';  // Zorg dat je deze correct configureert en importeert


function ContactformOppasser({ onSubmit }) {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [notificationTime, setNotificationTime] = useState('');
  const [addressValid, setAddressValid] = useState(true);
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [priceOption, setPriceOption] = useState('');  // Prijskeuze

  const form = useRef();
  const localizer = momentLocalizer(moment);

  const handleSelectSlot = ({ start }) => {
    const dateStr = moment(start).format('YYYY-MM-DD');
    setSelectedDates(prevDates =>
      prevDates.includes(dateStr)
        ? prevDates.filter(date => date !== dateStr)
        : [...prevDates, dateStr]
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email && !phone) {
      alert("Vul minimaal een e-mailadres of telefoonnummer in.");
      return;
    }

    const isValidAddress = await validateAddress(address);
    setAddressValid(isValidAddress);

    if (isValidAddress) {
      await onSubmit({ name, address, email, phone, message, selectedDates, notificationTime });
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000);
    }

    sendEmails();
  };

  const validateAddress = async (address) => {
    return true;  // Stel dat dit een API-aanroep is
  };

  const sendEmails = () => {
    const customerMessage = `Bedankt voor uw inschrijving als oppasser!
      Hieronder ziet u uw ingevulde data:
      Naam: ${name}
      Beschikbaarheid: ${selectedDates.join(", ")}
      Hoeveel dagen van tevoren willen weten wanneer op te passen: ${notificationTime}

      Aangezien wij nog niet in de testfase zitten, betekent dit dat de bovenstaande gegevens nog niet van toepassing is.
      Zodra wij in de testfase gaan bent u een van de eerste die wordt gecontacteerd om Snuffelmatch te mogen uittesten!
      `;

    const templateParams1 = {
      user_name: name,
      user_email: email,
      message: customerMessage,
      // Andere benodigde parameters voor de eerste e-mail
    };

    // Verstuur de eerste e-mail naar de klant
    emailjs.send(CustomerServiceId, CustomerTemplateId, templateParams1, YOUR_USER_ID)
      .then(response => console.log('Eerste e-mail verzonden!', response))
      .catch(err => console.error('Fout bij verzenden eerste e-mail:', err));
  };



  return (
    <form ref={form} onSubmit={handleSubmit}>
      <FormGroup className={classnames({ focused: nameFocused })}>
      <Label>Wat is uw naam?</Label>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-user-run" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Naam"
            type="text"
            onFocus={() => setNameFocused(true)}
            onBlur={() => setNameFocused(false)}
            onChange={e => setName(e.target.value)}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
      <Label>Wat is uw adres?</Label>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-square-pin" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Adres"
            type="text"
            invalid={!addressValid}
            onFocus={() => setAddressValid(true)}
            onChange={e => setAddress(e.target.value)}
          />
          {!addressValid && <FormFeedback>Adres is niet geldig.</FormFeedback>}
        </InputGroup>
      </FormGroup>

      <FormGroup>
      <Label>Wat is uw telefoonnummer?</Label>
      <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-mobile-button" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
          placeholder="Telefoonnummer"
          type="tel"
          onChange={e => setPhone(e.target.value)}
          required
        />
        </InputGroup>
        
      </FormGroup>

      <FormGroup className={classnames({ focused: emailFocused })}>
      <Label>Wat is uw email?</Label>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Emailadres"
            type="email"
            onFocus={() => setEmailFocused(true)}
            onBlur={() => setEmailFocused(false)}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <Label>Prijskeuze:</Label>
        <div>
          <Button color="secondary" onClick={() => setPriceOption('Gratis')} active={priceOption === 'Gratis'}>
            Gratis
            <span style={{
              display: 'block', // Zorgt ervoor dat de span een eigen regel inneemt
              fontSize: '12px', // Maakt de font grootte kleiner
              color: '#666', // Maakt de tekstkleur lichter/donkerder
              marginTop: '4px' // Voegt ruimte toe tussen de knoptekst en de span tekst
            }}>Max. 5 matches per maand</span>
          </Button>
          <Button color="secondary" onClick={() => setPriceOption('Premium')} active={priceOption === 'Premium'}>
            Premium
            <span style={{
              display: 'block',
              fontSize: '12px',
              color: '#666',
              marginTop: '4px'
            }}>oneindig veel matches per maand</span>
          </Button>
        </div>
      </FormGroup>

      <FormGroup>
        <label>Hoeveel dagen van tevoren wilt u te horen krijgen dat u kunt oppassen?</label>
        <Input
          placeholder="Hoeveel dagen?"
          type="text"
          onChange={e => setNotificationTime(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
      <Label>Welke dagen kunt u al oppassen?</Label>
        <Calendar
          localizer={localizer}
          events={selectedDates.map(date => ({
            start: new Date(date),
            end: new Date(date),
            title: 'Geselecteerd'
          }))}
          startAccessor="start"
          endAccessor="end"
          onSelectSlot={handleSelectSlot}
          selectable
          views={['month']}
          defaultDate={new Date()}
          style={{ height: 500 }}
        />
      </FormGroup>

      

      <Button block className="btn-round" color="default" size="lg" type="submit">
        Versturen
      </Button>

      {showSuccessMessage && (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>
          <span className="alert-inner--text ml-1">
            <strong>Success!</strong> Je bericht is verstuurd!
          </span>
        </UncontrolledAlert>
      )}
    </form>
  );
}

export default ContactformOppasser;
