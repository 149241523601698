import React from "react";
import { Card, Container, Row, Col } from "reactstrap";

import NavbarMain from "components/Navbars/NavbarMain.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import yf from "../../assets/img/crew pictures/yf.jpeg";
import maarten from "../../assets/img/crew pictures/maarten.jpeg";
import siebe from "../../assets/img/crew pictures/siebe.jpeg";
import simon from "../../assets/img/crew pictures/simon.jpg";
import jaimy from "../../assets/img/crew pictures/jaimy.jpeg";

import usePageTracking from "hooks/pagetracking";

function OverOns() {
  usePageTracking();
  return (
    <>
      <NavbarMain />
      <main className="profile-page" >
        <section className="section-profile-cover section-shaped my-0">
          {/* Circles background */}
          <div className="shape shape-style-1 shape-default alpha-4">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section">
          <Container>
            {/*JAIMY INTODUCTION */}
            <Card className="card-profile shadow mt--300 mb-5">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={jaimy}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-8">
                  <h3>
                    Jaimy de Lannoy{" "}
                    <span className="font-weight-light">, 19</span>
                  </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Geleen, Nederland
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    CFO
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Centrum Voor Ondernemers
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>
                        {/*text */}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>

            {/* Increased Spacer Element */}
            <div style={{ paddingTop: '35%' }}></div> {/* Increased the padding here */}

            {/*SIEBE INTODUCTION */}
            <Card className="card-profile shadow mt--300 mb-5">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={siebe}
                          />
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-8">
                  <h3>
                    Siebe Verhoeven{" "}
                    <span className="font-weight-light">, 20</span>
                  </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Oirschot, Nederland
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Web Developer - Techniek
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Centrum Voor Ondernemers
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>
                        {/*text */}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>

            {/* Increased Spacer Element */}
            <div style={{ paddingTop: '35%' }}></div> {/* Increased the padding here */}
        

            {/*MAARTEN INTODUCTION */}
            <Card className="card-profile shadow mt--300 mb-5">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={maarten}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-8">
                  <h3>
                    Maarten van Dorp{" "}
                    <span className="font-weight-light">, 20</span>
                  </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Sittard, Nederland
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Web Developer - Techniek
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Centrum Voor Ondernemers
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>
                        {/*text */}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>

            {/* Increased Spacer Element */}
            <div style={{ paddingTop: '35%' }}></div> {/* Increased the padding here */}
        
            {/*SIMON INTODUCTION */}
            <Card className="card-profile shadow mt--300 mb-5">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={simon}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-8">
                  <h3>
                    Simon Walk{" "}
                    <span className="font-weight-light">, 20</span>
                  </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Eindhoven, Nederland
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    CFO
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Centrum Voor Ondernemers
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>
                        {/*text */}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>

            {/* Increased Spacer Element */}
            <div style={{ paddingTop: '35%' }}></div> {/* Increased the padding here */}
        
            {/*YF INTODUCTION */}
            <Card className="card-profile shadow mt--300 mb-5">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={yf}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-8">
                  <h3>
                    Yf Bakemans{" "}
                    <span className="font-weight-light">, 25</span>
                  </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Best, Nederland
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Marketing & Sales
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Centrum Voor Ondernemers
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>
                        {/*text */}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default OverOns;
