import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import  Icon1  from "../../assets/img/icons/common/depositphotos_186481648-stock-illustration-human-hand-animal-paws-sign.jpg";
import  Icon2  from "../../assets/img/icons/common/depositphotos_214274674-stock-illustration-walking-the-dog-vector-icon.jpg";
import  Icon3  from "../../assets/img/icons/common/depositphotos_223571950-stock-illustration-plott-hound-dog-transparent-icon.jpg";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class NavbarIndex extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main bg-primary navbar-transparent1 headroom" 
            expand="lg"
            id="navbar-main"
            style={{
                paddingTop: "0%", // Reduce padding to decrease height
                paddingBottom: "0%", // You might want to reduce padding at the bottom as well
              }}
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/SnuffelMatch Logo.png")}
                  style={{ maxWidth: '100px', height: 'auto' }}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          src={require("assets/img/brand/SnuffelMatch Logo.png")}
                          alt="..."
                          style={{ maxWidth: '100px', height: 'auto' }}
                          className="logo-big"
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                    <CustomLink to={"/diensten"} className="nav-link nav-link-inner--text"                         
                      style={{
                        fontSize: '1rem', // Adjust font size as needed
                        fontWeight: 'bold', // This will make the text bolder
                        color: 'black' 
                        }}>
                          Diensten▼
                    </CustomLink>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media className="d-flex align-items-center" href="/diensten">
                          <img src={Icon1} className="icon icon-shape bg-gradient-primary rounded-circle text-white" alt="Uitlaten" />
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Uitlaten
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Zoek een oppasser die voor jou hond wilt oppassen!
                            </p>
                          </Media>
                        </Media>
                        <Media className="d-flex align-items-center" href="/diensten">
                          <img src={Icon2} className="icon icon-shape bg-gradient-success rounded-circle text-white" alt="Oppassen" />
                          <Media body className="ml-3">
                            <h6 className="heading text-succes mb-md-1">
                              Oppassen
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Laat een oppasser een of meerdere dagen op je hond passen!
                            </p>
                          </Media>
                        </Media>
                        <Media className="d-flex align-items-center" href="/diensten">
                          <img src={Icon3} className="icon icon-shape bg-gradient-warning rounded-circle text-white" alt="Zorg" />
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Zorg
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Laat een oppasser voor jou hond zorgen!
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <CustomLink to={"/prijzen"} className="nav-link nav-link-inner--text"                         
                    style={{
                        fontSize: '1rem', // Adjust font size as needed
                        fontWeight: 'bold', // This will make the text bolder
                        color: 'black' 
                    }}>
                        Prijzen
                    </CustomLink>
                  </NavItem>
                  <NavItem>
                    <CustomLink to={"/overons"} className="nav-link nav-link-inner--text"                         
                    style={{
                        fontSize: '1rem', // Adjust font size as needed
                        fontWeight: 'bold', // This will make the text bolder
                        color: 'black' 
                    }}>
                        Over Ons
                    </CustomLink>
                  </NavItem>
                  <NavItem>
                    <CustomLink to={"/contact"} className="nav-link nav-link-inner--text"                         
                    style={{
                        fontSize: '1rem', // Adjust font size as needed
                        fontWeight: 'bold', // This will make the text bolder
                        color: 'black' 
                    }}>
                        Contact
                    </CustomLink>
                  </NavItem>
                  <NavItem>
                    <CustomLink to={"/feedback"} className="nav-link nav-link-inner--text"                         
                    style={{
                        fontSize: '1rem', // Adjust font size as needed
                        fontWeight: 'bold', // This will make the text bolder
                        color: 'black' 
                    }}>
                        Feedback
                    </CustomLink>
                  </NavItem>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto " navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com"
                      id="tooltip356693867"
                      target="_blank"
                      style={{ color: 'black', 
                      fontWeight: 'bold'}} 
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Volg ons op Instagram!
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <Button
                      className="btn-success btn-icon"
                      color="success"
                      href=""
                      target="_blank"
                    >
                    {/*<CustomLink to={"/landingpage"} className="nav-link-inner--text ml-1">Schrijf je in!</CustomLink>*/} 
                    <CustomLink to={"/contact"} className="nav-link-inner--text ml-1"                         
                    style={{
                        fontWeight: 'bold', // This will make the text bolder
                        color: 'black' 
                    }}>
                        Schrijf je in!
                    </CustomLink>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

export default NavbarIndex;
