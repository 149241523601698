import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledAlert,
  Label,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AdministrationServiceId, AdministrationTemplateId, YOUR_USER_ID } from '../../assets/email/EmailConfig';

import NavbarMain from "components/Navbars/NavbarMain.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import usePageTracking from "hooks/pagetracking";

function Feedback() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const userEmail = 'maartenvandorp10@gmail.com';

  const form = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      alert("Zorg ervoor dat alle velden zijn ingevuld.");
      return;
    }

    // Verwerk de feedback hier, bijvoorbeeld opslaan in een database of een API-aanroep doen
    console.log({ name, email, feedback });
    
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 5000);

    // Optioneel: Hier kun je een functie toevoegen om emails te versturen als dat nodig is.
    sendEmails();
  };

  const sendEmails = () => {
    const customerMessage = `Feedback formulier!
      Naam: ${name}
      Email: ${email}
      Feedback: ${feedback}
      `;

    const templateParams1 = {
      from_name: name,
      message: customerMessage,
      // Andere benodigde parameters voor de eerste e-mail
    };

    // Verstuur de eerste e-mail naar de klant
    emailjs.send(AdministrationServiceId, AdministrationTemplateId, templateParams1, YOUR_USER_ID)
      .then(response => console.log('Eerste e-mail verzonden!', response))
      .catch(err => console.error('Fout bij verzenden eerste e-mail:', err));
  };

  return (
    <>
      <NavbarMain />
      <main className="section section-lg section-shaped pb-250"> 
        <div style={{ paddingTop: '15%' }}></div>
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <div style={{ paddingTop: '5%' }}></div>

        <section className="section section-lg pt-lg-0 section-contact-us">
          <Container>
            <Row className="justify-content-center mt--300">
              <Col lg="8">
                <Card className="bg-gradient-secondary shadow">
                  <CardBody className="p-lg-5">
                    <h4 className="mb-1">Wilt u Feedback geven over ons?</h4>
                    <p className="mt-0">
                      Geef hieronder feedback! Alvast bedankt!
                    </p>
                    <form ref={form} onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label>Wat is uw naam?</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Naam"
                            type="text"
                            onChange={e => setName(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <Label>Wat is uw email?</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Emailadres"
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <Label>Wat voor feedback heeft u?</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-align-center" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Feedback"
                            type="text"
                            onChange={e => setFeedback(e.target.value)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>

                      <Button block className="btn-round" color="default" size="lg" type="submit">
                        Versturen
                      </Button>

                      {showSuccessMessage && (
                        <UncontrolledAlert color="success" fade={false}>
                          <span className="alert-inner--icon">
                            <i className="ni ni-like-2" />
                          </span>
                          <span className="alert-inner--text ml-1">
                            <strong>Success!</strong> Je bericht is verstuurd!
                          </span>
                        </UncontrolledAlert>
                      )}
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default Feedback;
