import React from "react";
import usePageTracking from "hooks/pagetracking";

import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import NavbarMain from "components/Navbars/NavbarMain.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function Prijzen() {
  usePageTracking();
  return (
    <>
      <NavbarMain />
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 99"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <Row className="justify-content-center align-items-center" >
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-info rounded-circle mb-4">
                          <i className="ni ni-active-40" />
                        </div>
                        <h6 className="text-info text-uppercase">
                          Standaard Abonnement<pre></pre> (€3,- per maand)
                        </h6>
                        <p className="description mt-3" style={{fontWeight: 'bold'}}>
                        Ontdek de ideale partner voor jouw hond met ons Standaard abonnement! 
                        Maak gebruik van onze geavanceerde service om moeiteloos en efficiënt de 
                        <span style={{color: "green"}}> perfecte match</span> te vinden. 
                        Sluit je vandaag nog aan bij onze community en begin met het verkennen 
                        van onbegrensde mogelijkheden voor jou en je trouwe viervoeter!</p>
                        <div>
                          <Badge color="info" pill className="mr-1">
                            Betrouwbaar
                          </Badge>
                          <Badge color="info" pill className="mr-1">
                            Beperkte Matches
                          </Badge>
                          <Badge color="info" pill className="mr-1">
                            Semi-Flexibel
                          </Badge>
                        </div>
                        <Button
                          className="mt-4"
                          color="info"
                          href="/contact"  
                        >
                          <i className="ni ni-spaceship icon-spacing" style={{ marginRight: '10px' }}/>
                          Schrijf je nu in!
                          </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-danger rounded-circle mb-4">
                          <i className="ni ni-money-coins" />
                        </div>  
                        <h6 className="text-danger text-uppercase">
                          Premium Abonnement <pre></pre> (€5,- per maand)
                        </h6>
                        <p className="description mt-3" style={{fontWeight: 'bold'}}>
                        Met ons Premium abonnement ontgrendel je onbeperkte toegang tot matches, 
                        waardoor je op een <span style={{color: "green"}}>flexibele en betrouwbare</span> wijze de perfecte match 
                        kunt vinden! Profiteer nu en maak het verschil in je zoektocht.                          
                        </p>
                        <div>
                          <Badge color="danger" pill className="mr-1">
                            Betrouwbaar
                          </Badge>
                          <Badge color="danger" pill className="mr-1">
                            Snelle Matches!
                          </Badge>
                          <Badge color="danger" pill className="mr-1">
                            Flexibel
                          </Badge>
                        </div>
                          <Button
                            className="mt-4"
                            color="danger"
                            href="/contact"
                          >
                            <i className="ni ni-spaceship icon-spacing" style={{ marginRight: '10px' }}/>
                            Schrijf je nu in!
                          </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default Prijzen;