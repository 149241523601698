import React, {useState} from "react";
import classnames from "classnames";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import NavbarMain from "components/Navbars/NavbarMain.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import usePageTracking from "hooks/pagetracking";

function Diensten() {
  usePageTracking();

  return (
    <>
      <NavbarMain />
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="7">
                    <h1 className="display-3 text-white">
                      Alle 3 de services op een rijtje!
                    </h1>
                    <p className="lead text-white">
                      Bij ons staan kwaliteit en zorg voor jouw huisdier voorop. 
                      Maak kennis met onze drie kernservices, ontworpen om aan al
                       jouw behoeften te voldoen. Van dagelijkse uitlaatservices 
                       tot liefdevolle oppas en langere tijd oppas, wij zorgen 
                       ervoor dat jouw huisdier altijd gelukkig en gezond blijft.</p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 99"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                          <i className="ni ni-check-bold" />
                        </div>
                        <h6 className="text-primary text-uppercase">
                          Uitlaatservice
                        </h6>
                        <p className="description mt-3">
                          Ons platform verbindt jou met de ideale uitlater voor je hond in jouw omgeving.
                          We zorgen voor een betrouwbare match op basis van jouw specifieke behoeften en
                          de voorkeuren van je hond. Geniet van de gemoedsrust die komt met een deskundig
                          geselecteerde uitlater die jouw hond de nodige beweging en plezier biedt.
                        </p>
                        <div>
                          <Badge color="primary" pill className="mr-1">
                            Betrouwbaar
                          </Badge>
                          <Badge color="primary" pill className="mr-1">
                            Flexibel
                          </Badge>
                          <Badge color="primary" pill className="mr-1">
                            Snel
                          </Badge>
                        </div>
                        <Button
                          className="mt-4"
                          color="primary"
                          href="/contact"
                        >
                          Schrijf je in!
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                          <i className="ni ni-istanbul" />
                        </div>
                        <h6 className="text-success text-uppercase">
                          Oppasservice
                        </h6>
                        <p className="description mt-3">
                          Zoek je iemand om op je hond te passen terwijl je aan het werk bent of voor 
                          korte periodes weg moet? Via ons platform vind je gemakkelijk een betrouwbare 
                          en liefdevolle oppasser. Wij matchen jouw hond met een oppasser die past bij
                          zijn of haar karakter en jouw specifieke wensen, zodat je hond in goede handen is.
                        </p>
                        <div>
                          <Badge color="success" pill className="mr-1">
                            Betrouwbaar
                          </Badge>
                          <Badge color="success" pill className="mr-1">
                            Flexibel
                          </Badge>
                          <Badge color="success" pill className="mr-1">
                            Snel
                          </Badge>
                        </div>
                        <Button
                          className="mt-4"
                          color="success"
                          href="/contact"
                        >
                          Schrijf je in!
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                          <i className="ni ni-planet" />
                        </div>
                        <h6 className="text-warning text-uppercase">
                          Vakantie Oppasservice
                        </h6>
                        <p className="description mt-3">
                          Plan je een langere afwezigheid of vakantie? Ons platform helpt je bij 
                          het vinden van de perfecte vakantieoppas voor je hond. Wij zorgen voor 
                          een zorgvuldige match met oppassers die ervaring hebben met langdurige 
                          zorg en die je huisdier met open armen ontvangen, zodat je zonder zorgen 
                          van je vakantie kunt genieten.
                        </p>
                        <div>
                          <Badge color="warning" pill className="mr-1">
                            Betrouwbaar
                          </Badge>
                          <Badge color="warning" pill className="mr-1">
                            Flexibel
                          </Badge>
                          <Badge color="warning" pill className="mr-1">
                            Snel
                          </Badge>
                        </div>
                        <Button
                          className="mt-4"
                          color="warning"
                          href="/contact"
                        >
                          Schrijf je in!
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default Diensten;
